import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Pradeep Budhathoki',
  subtitle: 'Electronics and Communication Engineering student at IOE,Pulchowk Campus ',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'prad.png',
  paragraphOne: 'My name is Pradeep Budhathoki;Electronics and Communication Engineering student at IOE,Pulchowk Campus currently in final year and residing in Kathmandu.I like to keep learing and improving myself.',
  paragraphTwo: 'My skillset includes HTML,CSS & Bootstrap,Javascript,Python.I\'m currently indulged in learningand working in frameworks like Django for backend,React(frontend) and  Mysql  database.',

  paragraphThree: '',
  //resume: 'favicon.png', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'library.png',
    title: 'Library',
    info: 'This project is about a simple library management system where the logged in users can view all available books and their authores, borrow them,check remaining days after issue,see details of each book, etc.',
    info2: '',
    url: 'http://djangosimplelibrary.herokuapp.com/catalog/',
    repo: 'https://github.com/PradeepBudhathoki/django-simple-library', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'itjob.png',
    title: 'IT Job Vacancies in Nepal',
    info: 'This django project with mysql as database is about the IT job openings at various companies in Nepal.Features such as job listing,details and search functionality etc are included.',
    info2: '',
    url: '',
    repo: 'https://github.com/PradeepBudhathoki/itjobvacancy-django-mysql', // if no repo, the button will not show up
  },
  
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to work together with me?',
  btn: 'Mail',
  email: 'bcpradeep47@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/PradeepBudhath9',
    },
    {
      id: nanoid(),
      name: 'medium',
      url: 'https://medium.com/@bcpradeep47',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/pradeep-budhathoki-802247188/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/PradeepBudhathoki',
    },
  ],
};


